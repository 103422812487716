import * as React from "react";
import Layout from "../components/global/layout/layout";
import {graphql} from "gatsby";
import SmallHeader from "../components/global/small-header/small-header";

export const pageQuery = graphql`
  query GroupTherapyQuery {
    site {
      siteMetadata {
        title
        description
        titleSuffix
        titleDivider
      }
    }
  }
`;

export default props => {
  return (
    <Layout title={props.data.site.siteMetadata.title}
            description={props.data.site.siteMetadata.description}
            titleSuffix="Gruppentherapie"
            titleDivider={props.data.site.siteMetadata.titleDivider}>
      <SmallHeader title={'Die Gruppentherapie'}
                   backgroundImage={'/images/gruppentherapie/weber-gruppentherapie.jpg'}/>
      <div className="container">
        <div className="row mt-5 mb-2">
          <div className="col-12">
            <p>
              Eine psychotherapeutische Gruppe besteht aus 3 bis 9 TeilnehmerInnen und findet wöchentlich bzw. alle zwei Wochen statt.
            </p>
            <p className={`py-2`}>
              In den ersten Terminen geht es darum, sich kennen zu lernen und ein Gemeinschaftsgefühl zu entwickeln. Wenn ich mich mit den eigenen Problemen nicht mehr so alleine fühle, kann dies bereits eine deutliche Entlastung bringen. Gleichzeitig stellt die Gruppensituation ein Abbild der äußeren Welt dar, in der wir alle Leben, wie Familie, Schule, Freundeskreis und Arbeitswelt. Probleme, die wir hier erleben, werden in der Regel auch in der Gruppe auftreten. Dies kann Angst auslösen, eröffnet aber auch die Möglichkeit, sich diese Probleme anzuschauen und zu bearbeiten.
            </p>
            <b className="pt-2">
              Folgende Gruppen biete ich an:
            </b>
            <ul className="pl-4">
              <li className="pb-2 pt-3">
                Gruppe für Grundschulkinder
              </li>
              <li className="py-2">
                Gruppe für weibliche Jugendliche im Alter zwischen 13 und 16 Jahren
              </li>
              <li className="py-2">
                Gruppe für Jungen im Alter zwischen 12 und 14 Jahren
              </li>
              <li className="py-2">
                Gruppe für Jugendliche und junge Erwachsene
              </li>
              <li className="py-2">
                Elterngruppen
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
}
