import React from 'react';
import Styles from './small-header.module.scss';

export default props => {
  return (
    <div className={`d-flex position-relative align-items-end ${Styles.headerImage}`} style={{ backgroundImage: `url(${props.backgroundImage})` }}>
      <div className={`position-absolute ${Styles.overlay}`}>
      </div>
      <div className="container">
        <div className="row mb-3">
          <div className="col-12 col-md-9 col-lg-10 color-white">
            <h1 className="text-uppercase">
              {props.title}
            </h1>
            <p className={`mt-3 ${Styles.subLine}`}>
              {props.description}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
